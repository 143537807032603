@import url('https://fonts.googleapis.com/css2?family=Exo&display=swap');

:root{
    font-size: calc(0.5em + 1vw);
    --main-color: #2bf;
  }
  
  * {
    font-family: 'Exo', sans-serif;
    box-sizing: border-box;
  }
  
  body,
  html,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  

#App{
    width: 100%;
    min-height: 100%;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
}