#display{
    font-size: 1rem;
    width: 7em;
    height: 3em;
    background-color: #f55;
    border-radius: 0.3em;
    align-self: center;
    color: white;
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    font-weight: bold;
    box-shadow: 0 5px 5px 5px rgba(0,0,0,0.1);
}


#display.on{
    background-color: #222;
    background-color: #7f7;
    color:black;
}