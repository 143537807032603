@import url(https://fonts.googleapis.com/css2?family=Exo&display=swap);
#display{
    font-size: 1rem;
    width: 7em;
    height: 3em;
    background-color: #f55;
    border-radius: 0.3em;
    align-self: center;
    color: white;
    display: flex;
    justify-content: center;
    justify-self: center;
    align-items: center;
    font-weight: bold;
    box-shadow: 0 5px 5px 5px rgba(0,0,0,0.1);
}


#display.on{
    background-color: #222;
    background-color: #7f7;
    color:black;
}
.drum-pad {
  font-size: 2em;
  width: 3.5em;
  margin: 0.2em;
  height: 3.5em;
  background-color: rgba(0, 0, 0, 0.8);
  box-shadow: 0 5px 5px 5px rgba(0,0,0,0.1);
  display: flex;
  font-weight: bold;
  border-radius: 0.5em;
  color: white;
  justify-content: center;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: pointer;
}



.drum-pad.on {
  background-color: var(--main-color);
}

#pad-bank {
  display: flex;
  flex-wrap: wrap;
  grid-area: pad-bank;
  width: 30em;
  height: 30em;
  justify-self: center;
  align-items: center;
  justify-content: center;
}

.toggle {
  display: flex;
  flex-direction: column;
  margin: 1em;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.toggle-label {
  margin: 0;
  font-weight: bold;
}

.toggle-switch {
  font-size: 1rem;
  width: 4em;
  height: 1.5em;
  background-color: #222;
  border-radius: 0.2em;
  padding: 0.15em;
  display: flex;
  flex-direction: row-reverse;
}

.toggle-switch div {
  height: 100%;
  border-radius: 0.2em;
  width: 50%;
  background-color: var(--main-color);
}

.toggle-switch.off {
  flex-direction: row;
}

#drum-machine {
  font-size: 0.6rem;
  width: auto;
  height: auto;
  background-color: #eee;
  display: grid;
  grid-template:
    "pad-bank display" 1fr
    "pad-bank toggles" 1fr
 
    / 70% 30%;
  border-radius: 1em;
  padding: 2em;
}

#toggles-container {
  grid-area: toggles;
}


@media (max-width: 900px){
  #drum-machine {
    font-size: 0.8rem;
    grid-template:
      "pad-bank pad-bank" auto
      "display toggles" auto     
      / auto;
  
  }

}
:root{
    font-size: calc(0.5em + 1vw);
    --main-color: #2bf;
  }
  
  * {
    font-family: 'Exo', sans-serif;
    box-sizing: border-box;
  }
  
  body,
  html,
  #root {
    height: 100%;
    width: 100%;
    margin: 0;
  }
  

#App{
    width: 100%;
    min-height: 100%;
    background-color: #2bf;
    background-color: var(--main-color);
    display: flex;
    justify-content: center;
    align-items: center;
}
