.toggle {
  display: flex;
  flex-direction: column;
  margin: 1em;
  justify-content: center;
  align-items: center;
  cursor:pointer;
}

.toggle-label {
  margin: 0;
  font-weight: bold;
}

.toggle-switch {
  font-size: 1rem;
  width: 4em;
  height: 1.5em;
  background-color: #222;
  border-radius: 0.2em;
  padding: 0.15em;
  display: flex;
  flex-direction: row-reverse;
}

.toggle-switch div {
  height: 100%;
  border-radius: 0.2em;
  width: 50%;
  background-color: var(--main-color);
}

.toggle-switch.off {
  flex-direction: row;
}
